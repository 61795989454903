@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdRegular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdBold.otf');
    font-weight: 700;
}

body {
    height: 100%;
    width: 100%;
}

#root {
    min-height: 100vh;
}

/* Dev-Note: This is used to style the canvas provided from the metaphore/rendering package */
#video-canvas canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.2);
}

.color-picker {
    position: relative;
    height: 24px;
    display: flex;
}

.color-picker .input-color {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0;
    width: 24px;
    opacity: 0;
}

.color-picker .current-color {
    position: absolute;
    height: 5px;
    bottom: 0;
    width: 24px;
    left: 0;
    border: 1px solid black;
}

.template-text {
    width: 100%;
}

.template-text .MuiInputBase-root.Mui-focused {
    padding-top: 75px;
}

.scale-slider .MuiSlider-mark {
    width: 3px;
    height: 12px;
    border-radius: 10px;
    background-color: #878d96;
}

.scale-slider .MuiSlider-rail {
    background-color: #d0d0d0;
}

.crop-slider .MuiSlider-valueLabel,
.crop-slider .MuiSlider-valueLabel:before {
    background-color: #fff;
    color: #757575;
    border: 1px solid #757575;
    transition: all 0.3s ease-in-out;
}

.crop-slider .MuiSlider-valueLabel:before {
    border-top: none;
    border-left: none;
    bottom: -1px;
}

.crop-slider .MuiSlider-thumb:hover .MuiSlider-valueLabel,
.crop-slider .MuiSlider-thumb:hover .MuiSlider-valueLabel:before {
    background-color: #757575;
    color: #fff;
    border: 1px solid transparent;
}

.crop-slider .MuiSlider-thumb:hover .MuiSlider-valueLabel:before {
    border-top: none;
    border-left: none;
}
